import { useApi } from '@tenant/composables'

export type AddressTypesState = {
    addressTypes: string[]
}

export default {
    state: (): AddressTypesState => ({
        addressTypes: [],
    }),

    mutations: {
        setAddressTypes(state: AddressTypesState, addressTypes: []) {
            state.addressTypes = addressTypes
        },
    },

    getters: {
        addressTypes(state: AddressTypesState) {
            return state.addressTypes
        },
    },

    actions: {
        fetchAddressTypes({ commit }: { commit: (...args: any[]) => void }) {
            const { execute } = useApi('/api/address-types', 'GET')

            execute().then((response) => {
                const addressTypes = response.map(
                    (salutation: { id: string; name: string }) => ({
                        value: salutation.id,
                        label: salutation.name,
                    })
                )

                commit('setAddressTypes', addressTypes)
            })
        },
    },
}
